import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import Container from 'react-bootstrap/Container';
import Product from 'components/Product';
import { ProductPageProps } from './models';

const ProductPage: FC<ProductPageProps> = ({
  data: {
    allProduct: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
  },
  location: { pathname },
}) => {
  const { product, seo } = nodes[0];
  const {
    button,
    buttonLabel,
    buttonLink,
    name,
    packContent,
    productBulletList,
    productDescription,
    packshot,
    packshotImg,
    featuresTitle,
    packContentsTitle,
  } = product;

  const crumbsSettings = {
    crumbs,
    overWrittenPath: pathname,
    overWrittenLabel: name,
  };

  packshotImg.childImageSharp.fallbackUrl = packshot;

  return (
    <Layout crumbsSettings={crumbsSettings} seo={seo}>
      <Container fluid>
        <Product
          {...{
            productDescription,
            productBulletList,
            buttonLabel,
            buttonText: button,
            url: buttonLink?.[0]?.url,
            packContent,
            productName: name,
            image: packshotImg,
            featuresTitle,
            packContentsTitle,
          }}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($url: String) {
    allProduct(filter: { url: { eq: $url } }) {
      nodes {
        id
        url
        product {
          packshot
          id
          url
          button
          buttonLabel
          buttonLink {
            icon
            name
            published
            queryString
            trashed
            udi
            url
          }
          packshotImg {
            childImageSharp {
              fluid {
                aspectRatio
                base64
                originalImg
                src
                srcSet
              }
            }
          }
          name
          productDescription
          productBulletList
          packContent
          featuresTitle
          packContentsTitle
        }

        seo {
          seoMetaDescription
          seoMetaKeywords
          seoMetaTitle
        }
      }
    }
  }
`;

export default ProductPage;
