import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ProductFeatures, ProductImage, BuyNow, ProductTitle } from './ProductComponents';
import { ProductProps } from './models';

import './styles.scss';

const Product: FC<ProductProps> = ({
  productDescription,
  productBulletList,
  packContent,
  image,
  buttonLabel,
  url,
  productName,
  featuresTitle,
  packContentsTitle,
  buttonText,
}) => (
  <Container className="product" data-testid="product-body">
    <Row className="product__header">
      <Col xs="6" sm="7" className="product__title-container">
        <ProductTitle {...{ productName }} />
      </Col>
    </Row>
    <Row className="product__information">
      <Col xs="6" sm="5" className="product__assets">
        <ProductImage {...{ image, alt: productName }} />
        {url ? <BuyNow {...{ buttonLabel, buttonText, url }} /> : null}
      </Col>
      <Col xs="6" sm="7" className="product__details-container">
        <ProductFeatures
          {...{
            productDescription,
            productBulletList,
            packContent,
            featuresTitle,
            packContentsTitle,
          }}
        />
      </Col>
    </Row>
  </Container>
);

export default Product;
