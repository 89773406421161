import React, { FC } from 'react';
import { ProductFeaturesProps } from '../models';

const ProductFeatures: FC<ProductFeaturesProps> = ({
  productDescription,
  productBulletList,
  packContent,
  featuresTitle,
  packContentsTitle,
}) => (
  <div className="product__details" data-testid="features-product">
    <h2 className="product__details-title">{featuresTitle}</h2>
    <ul className="product__bullet-list">
      {productBulletList.map((item) => (
        <li key={item} className="product__bullet-item" data-testid="bullet-list-product">
          {item}
        </li>
      ))}
    </ul>
    <div className="product__pack-content">
      <span className="product__pack-content-label">{packContentsTitle}</span>
      {packContent}
    </div>
    <div className="product__description">{productDescription}</div>
  </div>
);

export default ProductFeatures;
