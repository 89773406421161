import React, { FC } from 'react';
import { Link } from 'gatsby';
import { BuyNowProps } from '../models';

const BuyNow: FC<BuyNowProps> = ({ buttonLabel, buttonText, url }) => (
  <div className="product__buy-now-container">
    <Link
      target="_blank"
      data-testid="buy-now-product"
      to={url}
      aria-label={buttonLabel}
      className="product__buy-now"
    >
      {buttonText}
    </Link>
  </div>
);

export default BuyNow;
