import React, { FC } from 'react';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { ProductImageProps } from '../models';

const ProductImage: FC<ProductImageProps> = ({ image, alt }) => (
  <div className="product__image-container">
    <UmbracoImage className="product-list-item__packshot" image={image.childImageSharp} alt={alt} />
  </div>
);

export default ProductImage;
