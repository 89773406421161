import React, { FC } from 'react';
import { ProductTitleProps } from '../models';

const ProductTitle: FC<ProductTitleProps> = ({ productName }) => (
  // eslint-disable-next-line
  <h1 className="product__title" tabIndex={0} data-testid="title-product">
    {productName}
  </h1>
);

export default ProductTitle;
